<template>
  <div>
    <div v-if="loading">
      <Spinner/>
    </div>
    <div v-else>
      <TeamAccountsFilter title="CREDITS" :filtersActive="hasActiveFilters" showOpen/>
      <TablePaginated
        name="Team Accounts Onboarding"
        :data="tableRows"
        searchable
        @queryUpdated="searchQuery = $event"
        :filterable="true"
        :filters-active="hasActiveFilters"
        :default-sort="['name']">
        <template slot-scope="props">
          <b-table-column label="Club Name"
            field="name"
            header-class="is-unselectable"
            sortable>
            {{ props.row.name }}
          </b-table-column>
          <b-table-column label="Club Size"
            field="size"
            header-class="is-unselectable"
            sortable>
            {{ props.row.size | capitalize }}
          </b-table-column>
          <b-table-column label="Sport"
            field="sport"
            header-class="is-unselectable"
            sortable>
            {{ props.row.sport | capitalize}}
          </b-table-column>
          <b-table-column label="Open Accounts"
            field="open_acc"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.open_acc }}
          </b-table-column>
          <b-table-column label="Num of Credits"
            field="num_credits"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.num_credits }}
          </b-table-column>
          <b-table-column label="Credit Amount"
            field="credit_amount"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.credit_amount | currency}}
          </b-table-column>
          <b-table-column label="# of ACH Team Fees"
            field="num_ach"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.num_ach }}
          </b-table-column>
          <b-table-column label="ACH Team Fee Amount"
            field="ach_amount"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.ach_amount | currency }}
          </b-table-column>
          <b-table-column label="# of CC Team Fees"
            field="num_cc"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.num_cc }}
          </b-table-column>
          <b-table-column label="CC Team Fee Amount"
            field="cc_amount"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.cc_amount | currency }}
          </b-table-column>
        </template>
        <template slot="empty">
          No Team Accounts
        </template>
      </TablePaginated>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import TeamAccountsFilter from '@/components/team_accounts/TeamAccountsFilter';
import TablePaginated from '@/components/layout/TablePaginated';
import Spinner from '@/components/common/Spinner';
import api from '@/http-playmetrics';

export default {
  components: {
    TeamAccountsFilter,
    TablePaginated,
    Spinner,
  },
  props: {
    loading: { type: Boolean, required: true },
    clubsById: { type: Object, required: true },
  },
  data() {
    return {
      searchQuery: '',
      tableData: [],
    };
  },
  computed: {
    hasActiveFilters() {
      return !_.isEmpty(this.filtersActive);
    },
    filtersActive() {
      return this.$store.getters.activeFiltersForContext('teamAccounts');
    },
    tableRows() {
      return this.tableData.flatMap((report) => {
        const club = this.clubsById[report.club_id];
        if (!club || club.is_test_club) return {};
        return {
          id: report.club_id,
          name: club.name,
          size: club.size || '-',
          sport: club.sports?.length ? club.sports[0].sport : '-',
          open_acc: report.num_open_accounts,
          active_acc: report.num_active_accounts,
          num_credits: report.credits || 0,
          credit_amount: report.credit_amount || 0,
          num_ach: report.ach_team_fees || 0,
          ach_amount: report.ach_team_fee_amount || 0,
          num_cc: report.cc_team_fees || 0,
          cc_amount: report.cc_team_fee_amount || 0,
        };
      }).filter(club => this.matchesName(club, this.searchQuery) && this.matchesFilter(club));
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      return api().get('cst/team_accounts/summaries/fa_credits_reports').then((res) => {
        if (res.status === 200) {
          this.tableData = res.data;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    matchesName(tableData, searchQuery) {
      return tableData?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase() ?? '');
    },
    matchesFilter(club) {
      if (!this.hasActiveFilters) return true;
      return Object.keys(this.filtersActive).every(key => this.filtersActive[key].matches(club));
    },
  },
};
</script>

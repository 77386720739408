<template>
  <b-tag class="status_badge"
         :class="badgeClasses"
         rounded>
    <span v-if="status === 'completed'">
      <fa-icon style="padding-left: 0; margin-right: 0.25rem" class="has-text-success" :icon="['far', 'check']" />
    </span>
    {{status | capitalize}}
  </b-tag>
</template>

<script>
export default {
  props: {
    status: { type: String, required: true },
  },
  data() {
    return {
      statusText: 'Test',
    };
  },
  computed: {
    badgeClasses() {
      if (this.status === 'completed') {
        return 'is-completed';
      }
      if (this.status === 'not started') {
        return 'is-danger';
      }
      if (this.status === 'onboarding') {
        return 'is-warning';
      }
      if (this.status === 'monitoring') {
        return 'is-orange has-text-white';
      }
      if (this.status === 'weekly calls') {
        return 'has-background-grey has-text-white';
      }
      return '';
    },
  },
};
</script>

<style scoped lang="sass">
.status_badge
  font-family: $family-montserrat
  font-weight: 600
  font-size: 0.75rem
  white-space: nowrap
.is-completed
  background-color: transparent
  padding-left: 0.25em
</style>

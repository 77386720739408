<template>
  <div class="merchant-accounts">
    <PageNavigation page-title="Merchant Accounts" :ctas="ctas" @ctaClicked="cta($event)" />
    <TablePaginated
      name="Merchant Accounts"
      :filters-active="hasActiveFilters"
      :data="filteredTableRows"
      @queryUpdated="searchQuery = $event"
      searchable>
      <template #header-left>
        <span class="label" v-if="filteredTableRows">
          {{filteredTableRows.length}} {{filteredTableRows.length === 1 ? 'merchant account' : 'merchant accounts'}} found
        </span>
      </template>
      <template #empty>
        <Spinner v-if="loading"/>
        <section v-else class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Merchant Accounts Found</p>
          </div>
        </section>
      </template>
      <template #default="{ row }">
        <b-table-column label="Club ID"
          field="club_id"
          sortable
          :header-selectable="false">
          {{row.club_id}}
        </b-table-column>
        <b-table-column label="Club Name"
          field="club_name"
          sortable
          :header-selectable="false">
          {{row.club_name}}
        </b-table-column>
        <b-table-column label="Vendor"
          field="vendor"
          sortable
          :header-selectable="false">
          {{row.vendor}}
        </b-table-column>
        <b-table-column label="Type"
          field="type"
          sortable
          :header-selectable="false">
          {{row.type}}
        </b-table-column>
        <b-table-column label="Merchant Account ID"
          field="id"
          sortable
          :header-selectable="false">
          {{row.id}}
          <span v-if="row.name !== ''" class="is-size-7">&ndash; {{ row.name }}</span>
        </b-table-column>
        <b-table-column label="Controller"
          field="merchant_name"
          sortable
          :header-selectable="false">
          {{row.merchant_name}}
        </b-table-column>
        <b-table-column label="ACH"
          field="ach"
          sortable
          :header-selectable="false">
          {{row.ach}}
        </b-table-column>
        <b-table-column label="Viable"
          field="viable"
          sortable
          :header-selectable="false">
          {{row.viable}}
        </b-table-column>
        <b-table-column label="Country"
          field="country"
          sortable
          :header-selectable="false">
          {{row.country}}
        </b-table-column>
        <b-table-column label="Actions" centered :header-selectable="false">
          <b-button @click="editMerchantAccount(row)" class="is-ghost px-3" aria-label="edit merchant account">
            <fa-icon :icon="['fas', 'pencil']" />
          </b-button>
          <b-dropdown position="is-bottom-left" :class="{ 'is-invisible': !row.showMoreActions }">
            <template #trigger>
              <b-button class="is-ghost px-3" aria-label="more actions">
                <fa-icon :icon="['far', 'ellipsis-h']" />
              </b-button>
            </template>
            <b-dropdown-item v-if="row.allowSetDefault" @click="confirmSetAsDefault(row)">
              Set as Default
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>
      </template>
    </TablePaginated>
    <MerchantAccountsFilter :filters-active="hasActiveFilters" @showTestClubs="showTestClubs = $event"/>
    <MerchantAccountModal
      ref="merchantAccountModal"
      :clubs="clubs"
      :enableable-clubs="enableableClubs"
      @dismissed="enableModalDismissed"
    />
    <SetAsDefaultMerchantAccount
      ref="setAsDefaultModal"
      @set-default="enableModalDismissed({ refreshData: true })"
      />
  </div>
</template>

<script>
import _ from 'lodash';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';
import TablePaginated from '@/components/layout/TablePaginated';
import MerchantAccountsFilter from '@/components/merchant/MerchantAccountsFilter';
import MerchantAccountModal from '@/components/merchant/MerchantAccountModal';
import SetAsDefaultMerchantAccount from '@/components/merchant/SetAsDefaultMerchantAccount';

export default {
  components: {
    MerchantAccountModal,
    MerchantAccountsFilter,
    SetAsDefaultMerchantAccount,
    Spinner,
    TablePaginated,
    PageNavigation,
  },
  data() {
    return {
      searchQuery: '',
      merchantAccounts: [],
      loading: false,
      showTestClubs: false,
    };
  },
  computed: {
    clubsById() {
      return _.keyBy(this.$store.getters.clubs, 'id');
    },
    merchantAccountsByClubId() {
      return _.groupBy(this.merchantAccounts, 'club_id');
    },
    showingMerchantAccounts() {
      return this.merchantAccounts.filter((ma) => {
        const club = this.clubsById[ma.club_id];
        if (!club) return false;
        return (!club.is_test_club || this.showTestClubs);
      });
    },
    tableRows() {
      return this.showingMerchantAccounts.map(m => {
        const isSecondary = this.isSecondary(m);
        const isDefault = m.is_default;
        const allowSetDefault = !isSecondary && !isDefault;
        const showMoreActions = allowSetDefault;
        return {
          ...m,
          viable: this.viability(m),
          club_name: this.clubsById[m.club_id]?.name,
          type: isSecondary ? 'Secondary' : 'Primary',
          merchant_name: m.controller?.name,
          ach: this.achActive(m),
          showMoreActions,
          allowSetDefault,
        };
      });
    },
    filteredTableRows() {
      return this.tableRows.filter(row => this.matchesFilter(row) && this.matchesQuery(row));
    },
    hasActiveFilters() {
      return !_.isEmpty(this.activeFilters);
    },
    activeFilters() {
      return this.$store.getters.activeFiltersForContext('merchantAccounts');
    },
    ENABLE_ACCOUNT() {
      return 'Enable Merchant Account';
    },
    ctas() {
      const ret = [];
      ret.push(this.ENABLE_ACCOUNT);
      return ret;
    },
    clubs() {
      return this.$store.getters.clubs;
    },
    enableableClubs() {
      return this.clubs.filter(club => !club.is_deleted &&
        !this.clubHasViableMerchant(club) &&
        this.usingRegistration(club));
    },
  },
  created() {
    this.fetchMerchantAccounts();
  },
  methods: {
    fetchMerchantAccounts() {
      this.loading = true;
      api().post('/cst/merchant_accounts/search?populate=user').then((res) => {
        this.merchantAccounts = res.data;
        this.loading = false;
      });
    },
    achActive(clubMerchant) {
      if (clubMerchant.flags.includes('AllowACH')) {
        return 'Yes';
      }
      return '';
    },
    viability(clubMerchant) {
      if (clubMerchant.club_id === 0) {
        return 'Yes';
      }
      return (clubMerchant.flags.indexOf('Disabled') < 0) && (clubMerchant.flags.indexOf('Enabled') >= 0) ? 'Yes' : 'No';
    },
    matchesQuery(row) {
      return (
        (row.club_name && row.club_name.toLowerCase().includes(this.searchQuery.toLowerCase())) ||
        (row.club_id && row.club_id.toString().includes(this.searchQuery.toLowerCase()))
      );
    },
    matchesFilter(row) {
      if (!this.hasActiveFilters) return true;
      return Object.keys(this.activeFilters).every(key => this.activeFilters[key].matches(row));
    },
    cta(action) {
      if (action === 'Enable Merchant Account') {
        this.$refs.merchantAccountModal.show();
      }
    },
    clubHasViableMerchant(club) {
      return _.some(this.merchantAccounts, cm => cm.club_id === club.id && !this.isArchived(cm));
    },
    isArchived(cm) {
      return cm.flags.indexOf('Archived') >= 0;
    },
    editMerchantAccount(row) {
      const allClubAccounts = this.merchantAccountsByClubId[row.club_id];
      this.$refs.merchantAccountModal.show(row, allClubAccounts);
    },
    enableModalDismissed(options) {
      if (options.refreshData) {
        this.fetchMerchantAccounts();
      }
    },
    usingRegistration(club) {
      return club?.config?.enable_program_administration;
    },
    isSecondary(cm) {
      return cm.flags.indexOf('Secondary') >= 0;
    },
    confirmSetAsDefault(row) {
      const allClubAccounts = this.merchantAccountsByClubId[row.club_id] || [];
      const currentDefault = allClubAccounts.find(acct => acct.id !== row.id && acct.is_default);
      this.$refs.setAsDefaultModal.showModal(row, currentDefault);
    },
  },
};
</script>

import { render, staticRenderFns } from "./StatusTag.vue?vue&type=template&id=ad9a7f4c&scoped=true"
import script from "./StatusTag.vue?vue&type=script&lang=js"
export * from "./StatusTag.vue?vue&type=script&lang=js"
import style0 from "./StatusTag.vue?vue&type=style&index=0&id=ad9a7f4c&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad9a7f4c",
  null
  
)

export default component.exports
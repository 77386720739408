<template>
  <div>
    <div v-if="loading">
      <Spinner/>
    </div>
    <div v-else>
      <TeamAccountsFilter title="ACCOUNTS" :filtersActive="hasActiveFilters" showRemaining/>
      <TablePaginated
        name="Team Accounts Onboarding"
        :data="tableRows"
        searchable
        @queryUpdated="searchQuery = $event"
        :filterable="true"
        :filters-active="hasActiveFilters"
        :default-sort="['name']">
        <template slot-scope="props">
          <b-table-column label="Club Name"
field="name"
header-class="is-unselectable"
sortable>
            {{ props.row.name }}
          </b-table-column>
          <b-table-column label="Club Size"
            field="size"
            header-class="is-unselectable"
            sortable>
            {{ props.row.size | capitalize }}
          </b-table-column>
          <b-table-column label="Sport"
            field="sport"
            header-class="is-unselectable"
            sortable>
            {{ props.row.sport | capitalize}}
          </b-table-column>
          <b-table-column label="Contract Date"
            field="contract_date"
            header-class="is-unselectable"
            sortable>
            {{ props.row.contract_date | formatDate }}
          </b-table-column>
          <b-table-column label="Contracted Accounts"
            field="contracted_accounts"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.contracted_accounts }}
          </b-table-column>
          <b-table-column label="Open"
            field="open_acc"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.open_acc }}
          </b-table-column>
          <b-table-column label="Remaining"
            field="remaining"
            header-class="is-unselectable"
            numeric
            sortable>
            <span :class="props.row.remaining < 0 ? 'has-text-danger' : ''">
              {{ props.row.remaining.toLocaleString() }}
            </span>
          </b-table-column>
          <b-table-column label="No Treasurer"
            field="num_no_treasurer"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.num_no_treasurer }}
          </b-table-column>
        </template>
        <template slot="empty">
          No Team Accounts
        </template>
      </TablePaginated>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import TeamAccountsFilter from '@/components/team_accounts/TeamAccountsFilter';
import TablePaginated from '@/components/layout/TablePaginated';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    TeamAccountsFilter,
    TablePaginated,
    Spinner,
  },
  props: {
    loading: { type: Boolean, required: true },
    clubs: { type: Array, required: true },
    financialAccountStatusByClubId: { type: Object, required: true },
    teamAccountsByClubId: { type: Object, required: true },
    merchantAccountsByClubId: { type: Object, required: true },
  },
  data() {
    return {
      searchQuery: '',
      activeTab: 0,
      contracts: [],
      showTestClubs: false,
    };
  },
  computed: {
    hasActiveFilters() {
      return !_.isEmpty(this.filtersActive);
    },
    filtersActive() {
      return this.$store.getters.activeFiltersForContext('teamAccounts');
    },
    tableRows() {
      return this.clubs.map(club => ({
        id: club.id,
        name: club.name,
        size: club.size || '-',
        sport: club.sports?.length ? club.sports[0].sport : '-',
        contract_date: this.merchantAccountsByClubId[club.id]?.length ?
          this.merchantAccountsByClubId[club.id][0].contract_date : '',
        contracted_accounts: this.merchantAccountsByClubId[club.id]?.length ?
          this.merchantAccountsByClubId[club.id][0].num_accounts_limit : 0,
        open_acc: this.financialAccountStatusByClubId[club.id]?.open_counter || 0,
        remaining: this.remainingAccounts(club),
        num_no_treasurer: this.countTeamAccountsNoTreasurer(this.teamAccountsByClubId[club.id]),
        test_club: club.is_test_club,
      })).filter(club => this.matchesName(club, this.searchQuery) && this.matchesFilter(club));
    },
  },
  methods: {
    countTeamAccountsNoTreasurer(teamAccounts) {
      let noTreasurers = 0;
      teamAccounts.forEach((teamAccount) => {
        if (!teamAccount.json?.card_holders?.length) {
          noTreasurers += 1;
          return;
        }
        noTreasurers += teamAccount.json?.card_holders.some(ch => ch.treasurer) ? 0 : 1;
      });
      return noTreasurers;
    },
    remainingAccounts(club) {
      const contractedAmount = this.merchantAccountsByClubId[club.id]?.length ?
        this.merchantAccountsByClubId[club.id][0].num_accounts_limit : 0;
      const openAccounts = this.financialAccountStatusByClubId[club.id]?.open_counter || 0;
      return contractedAmount - openAccounts;
    },
    matchesName(tableData, searchQuery) {
      return tableData?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase() ?? '');
    },
    matchesFilter(club) {
      if (!this.hasActiveFilters) return true;
      return Object.keys(this.filtersActive).every(key => this.filtersActive[key].matches(club));
    },
  },
};
</script>

import api from '@/http-playmetrics';

// state
const state = {
  users: [],
};

// getters
const getters = {
  users(state) {
    return state.users;
  },
  usersByDepartments: state => (departments) => {
    const users = departments.map(department => state.users.filter(user => user.admin_permissions.includes(`cst_tool.dept_${department}`)));
    return [...new Set(users.flat())];
  },
  webBuilderUsers(state) {
    return state.users.filter(user => user.email === 'bfranklin@playmetrics.com' || user.email === 'rmccollough@playmetrics.com');
  },
  salesUsers() {
    return {
      49478: 'Tim Chain',
      41754: 'Mike Bamber',
      263053: 'Jamil Gracia',
      574459: 'Adam Hayes',
      574463: 'Andrew McGinnis',
      1492241: 'Adam Neiberg',
      784294: 'Meredith Hamlet',
      1447923: 'Tommy Jerr',
      1447921: 'Josh Maurice',
      1570979: 'Daniel Pennisi',
    };
  },
};

// mutations
const mutations = {
  setUsers(state, payload) {
    state.users = payload;
  },
};

// actions
const actions = {
  fetchUsers(context) {
    return api().get('/cst/users').then((res) => {
      const users = res.data.filter(user => user.admin_permissions.filter(permission => permission.includes('cst_tool')).length > 0);
      context.commit('setUsers', users);
    }).catch((error) => {
      context.commit('setNotification', {
        message: error.message,
        success: false,
      });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div>
    <div v-if="loading">
      <Spinner/>
    </div>
    <div id="financial-summary-table" v-else>
      <PageNavigation
          :page-title="pageTitle"
          :back-button="backButton"
          @tabClicked="tabClicked($event)"
          @ctaClicked="cta($event)"
          :ctas="ctas">
        <template slot="left-bottom-slot">
          <StatusBadge :status="`ID: ${club.id}`"/>
        </template>
      </PageNavigation>
      <SetClubValuesModal ref="setClub" @refresh="loadClub"/>
      <div v-for="t in tables" :key="t.key">
        <TablePaginated
          bordered
          :name="t.key"
          :data="tableRows"
          :showPagination="false"
          :searchable="false"
          :filterable="false"
          scrollable>
          <template slot="table-header">
            <div :class="t.key === 'Payments' ? 'table-header__top' : 'table-header'"> {{ t.key }} </div>
          </template>
          <template slot-scope="props">
            <b-table-column
              :header-selectable="false"
              label="RR"
              field="risk_rating">
              {{ props.row.risk_rating }}
            </b-table-column>
            <b-table-column
              :header-selectable="false"
              numeric
              label="Exp APV"
              field="expected_apv">
              <div style="min-width: 4rem"> {{ props.row.expected_apv | currency }} </div>
            </b-table-column>
            <b-table-column
              :visible="t.key === 'Payments'"
              :header-selectable="false"
              numeric
              label="EPC"
              field="expected_prepayment_coefficient">
              <div style="min-width: 4rem"> {{ formatPercentage(props.row.expected_prepayment_coefficient) }} </div>
            </b-table-column>
            <b-table-column
              :visible="t.key === 'Payments'"
              :header-selectable="false"
              numeric
              label="APC"
              field="actual_prepayment_coefficient">
              <div style="min-width: 4rem"> {{ formatPercentage(props.row.actual_prepayment_coefficient) }} </div>
            </b-table-column>
            <b-table-column
              :visible="t.key === 'Payments'"
              :header-selectable="false"
              numeric
              label="Ann PV%"
              field="annual_processing_volume_percentage"
              :cell-class="getCellClass(props.row.annual_processing_volume_percentage, 'Payments', true)">
              <div style="min-width: 4rem"> {{ formatPercentage(props.row.annual_processing_volume_percentage) }} </div>
            </b-table-column>
            <b-table-column
              :visible="t.key === 'Refunds'"
              :header-selectable="false"
              numeric
              label="Ann RV%"
              field="annual_refund_volume_percentage"
              :cell-class="getCellClass(props.row.annual_refund_volume_percentage, 'Refunds', true)">
              <div style="min-width: 4rem"> {{ formatPercentage(props.row.annual_refund_volume_percentage) }} </div>
            </b-table-column>
            <b-table-column
              :visible="t.key === 'Chargebacks'"
              :header-selectable="false"
              numeric
              label="Ann CBV%"
              field="annual_chargeback_volume_percentage"
              :cell-class="getCellClass(props.row.annual_chargeback_volume_percentage, 'Chargebacks', true)">
              <div style="min-width: 4.5rem"> {{ formatPercentage(props.row.annual_chargeback_volume_percentage) }} </div>
            </b-table-column>
            <b-table-column
              v-for="(month , index) in props.row.payments_by_month"
              :key="index"
              :header-selectable="false"
              :label="month.date.format('MMM YY')"
              numeric
              :field="`${month.date.format('MMM YY')}`"
              :cell-class="getCellClass(props.row[t.value][index].value, t.key)">
              <div style="min-width: 4rem"> {{ formatPercentage(props.row[t.value][index].value) }} </div>
            </b-table-column>
          </template>
        </TablePaginated>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';
import TablePaginated from '@/components/layout/TablePaginated';
import Spinner from '@/components/common/Spinner';
import api from '@/http-playmetrics';
import StatusBadge from '@/components/onboarding/plans/StatusBadge';
import SetClubValuesModal from './SetClubValuesModal';

export default {
  components: {
    PageNavigation,
    TablePaginated,
    StatusBadge,
    SetClubValuesModal,
    Spinner,
  },
  data() {
    return {
      club: {},
      clubInternal: {},
      loading: false,
    };
  },
  computed: {
    tableRows() {
      return [{ ...this.club,
        risk_rating: this.clubInternal.risk_rating || 'N/A',
        expected_apv: this.clubInternal.expected_apv || '-',
        expected_prepayment_coefficient: this.clubInternal.expected_prepayment_coefficient / 100 || 0,
        actual_prepayment_coefficient: this.getActualPrepaymentCoefficient(this.club.groupedKpis[10016]),
        annual_processing_volume_percentage: this.getVolumePercentage(this.club.groupedKpis[10016], this.clubInternal.expected_apv),
        annual_refund_volume_percentage: this.getVolumePercentage(this.club.groupedKpis[10018], this.clubInternal.expected_apv),
        annual_chargeback_volume_percentage: this.getVolumePercentage(this.club.groupedKpis[10020], this.clubInternal.expected_apv),
        payments_by_month: this.getKpisByMonth(this.club.groupedKpis[10016], this.clubInternal.expected_apv),
        refunds_by_month: this.getKpisByMonth(this.club.groupedKpis[10018], this.clubInternal.expected_apv),
        chargebacks_by_month: this.getKpisByMonth(this.club.groupedKpis[10020], this.clubInternal.expected_apv),
      }];
    },
    tables() {
      return [
        { key: 'Payments', value: 'payments_by_month' },
        { key: 'Refunds', value: 'refunds_by_month' },
        { key: 'Chargebacks', value: 'chargebacks_by_month' },
      ];
    },
    systemValues() {
      return this.$route.params.systemValues;
    },
    pageTitle() {
      return this.club?.name || '';
    },
    backButton() {
      return {
        name: 'Back',
        routeNames: ['Financial Tracking'],
        routeFallback: 'Financial Tracking',
      };
    },
    EDIT_CLUB_VALUES() {
      return 'Edit Club Values';
    },
    ctas() {
      return [this.EDIT_CLUB_VALUES];
    },
  },
  created() {
    this.loadClub();
  },
  methods: {
    getCellClass(percentage, table, isAnnual) {
      if (table === 'Payments') {
        if (isAnnual) {
          if (percentage >= this.systemValues.annual_payment_risk_percentage) return 'is-danger';
          else if (percentage >= this.systemValues.annual_payment_concern_percentage) return 'is-warning';
        } else if (!isAnnual && percentage >= this.systemValues.monthly_payment_risk_percentage) {
          return 'is-danger';
        } else if (!isAnnual && percentage >= this.systemValues.monthly_payment_concern_percentage) {
          return 'is-warning';
        }
      } else if (table === 'Refunds') {
        if (isAnnual) {
          if (percentage >= this.systemValues.annual_refund_risk_percentage) return 'is-danger';
          else if (percentage >= this.systemValues.annual_refund_concern_percentage) return 'is-warning';
        } else if (!isAnnual && percentage >= this.systemValues.monthly_refund_risk_percentage) {
          return 'is-danger';
        } else if (!isAnnual && percentage >= this.systemValues.monthly_refund_concern_percentage) {
          return 'is-warning';
        }
      } else if (table === 'Chargebacks') {
        if (isAnnual) {
          if (percentage >= this.systemValues.annual_chargeback_risk_percentage) return 'is-danger';
          else if (percentage >= this.systemValues.annual_chargeback_concern_percentage) return 'is-warning';
        } else if (!isAnnual && percentage >= this.systemValues.monthly_chargeback_risk_percentage) {
          return 'is-danger';
        } else if (!isAnnual && percentage >= this.systemValues.monthly_chargeback_concern_percentage) {
          return 'is-warning';
        }
      }
      return '';
    },
    formatPercentage(num) {
      if (num === '-') return num;
      return `${num}%`;
    },
    cta(event) {
      if (event === this.EDIT_CLUB_VALUES) {
        this.$refs.setClub.showModal(this.club);
      }
    },
    calculateMean(arr) {
      const mean = arr.reduce((acc, curr) => acc + curr, 0) / 12;
      return mean;
    },
    calculateStdDev(arr, mean) {
      let num = 0;
      for (let i = 0; i < arr.length; i += 1) {
        num += (arr[i] - mean) ** 2;
      }
      return Math.sqrt(num / 12);
    },
    getLastYearKpis(kpis) {
      let lastYearKpis = [];
      const minDate = moment().subtract(1, 'years');
      if (!kpis) {
        for (let i = 0; i < 12; i += 1) lastYearKpis.push(0);
        return lastYearKpis;
      } else lastYearKpis = kpis.filter(kpi => minDate.isSameOrBefore(moment(kpi.captured_on))).map(kpi => kpi.value);
      return lastYearKpis;
    },
    getActualPrepaymentCoefficient(kpis) {
      const lastYearKpis = this.getLastYearKpis(kpis);
      const mean = this.calculateMean(lastYearKpis);
      if (!mean || !kpis) return '-';
      return Math.round(10000 * this.calculateStdDev(lastYearKpis, mean) / mean / Math.sqrt(12)) / 100;
    },
    getVolumePercentage(kpis, expectedApv) {
      if (!expectedApv || !kpis) return '-';
      return Math.round(10000 * (this.getLastYearKpis(kpis).reduce((acc, curr) => acc + curr, 0) / expectedApv)) / 100;
    },
    getKpisByMonth(kpis, expectedApv) {
      const arr = [];
      const kpiDate = moment().subtract(1, 'days');
      if (!expectedApv || !kpis) {
        for (let i = 0; i < 13; i += 1) {
          arr.push({ date: moment(kpiDate), value: '-' });
          kpiDate.month(kpiDate.month() - 1).endOf('month');
        } return arr;
      }
      for (let i = 0; i < 13; i += 1) {
        let kpiValue = kpis.find(kpi => kpiDate.isSame(moment(kpi.captured_on), 'month'))?.value;
        if (!kpiValue) kpiValue = 0;
        arr.push({ date: moment(kpiDate), value: Math.round(10000 * (kpiValue / expectedApv)) / 100 });
        kpiDate.month(kpiDate.month() - 1);
      } return arr;
    },
    loadClub() {
      this.loading = true;
      api().get(`/cst/clubs/${this.$route.params.club}?populate=kpis,kpis:merchant_payment_count,kpis:merchant_payment_amount,kpis:merchant_refund_count,kpis:merchant_refund_amount,kpis:merchant_dispute_count,kpis:merchant_dispute_amount`)
        .then((ret) => {
          this.club = { ...ret.data, groupedKpis: _.groupBy(ret.data.kpis, 'kpi_id') };
          this.loadClubInternal();
        });
      this.loading = false;
    },
    loadClubInternal() {
      api().get(`/cst/club_internals/${this.$route.params.club}/`)
        .then((ret) => {
          this.clubInternal = ret.data;
        });
    },
  },
};
</script>
<style lang="sass">
@import "~bulma/sass/utilities/mixins"
.table-header
  font-family: $family-montserrat
  font-weight: $weight-semibold
  font-size: $size-5
  margin-top: 2rem
  &__top
    font-family: $family-montserrat
    font-weight: $weight-semibold
    font-size: $size-5
#financial-summary-table
  .table th
    vertical-align: bottom
  .is-warning
    background-color: rgba($warning-dark, 0.25)
  .is-danger
    background-color: rgba($danger, 0.25)
    color: $grey-darker
</style>

<template>
  <div v-if="loading">
    <spinner />
  </div>
  <div id="financial-tracking-table" v-else>
    <PageNavigation
      page-title="Financial Tracking"
      :tabs="tabs"
      :active-tab="activeTab"
      @tabClicked="activeTab = $event"
      :dropdownCtas="dropdownCtas"
      @ctaClicked="cta($event)"
    />
    <SpecificClubExportModal ref="export_modal"/>
    <SpecificClubImportModal @refreshClubs="fetchFinancialKpis" ref="import_modal"/>
    <SetSystemModal @newValues="newSystemValues($event)" ref="system_modal"/>
    <TablePaginated
      name="Financial Tracking"
      ref="financialTable"
      :data="filteredClubs"
      searchable
      @queryUpdated="searchQuery = $event"
      :filters-active="hasFiltersActive"
      legend
      scrollable>
      <template slot="filter">
        <FTLegend/>
      </template>
      <template slot-scope="props">
        <b-table-column
          label="ID"
          field="id"
          sortable>
          {{ props.row.id }}
        </b-table-column>
        <b-table-column
          label="Club Name"
          field="name"
          sticky
          sortable>
          <div class="sticky-column">
            <a class="club-name" @click="viewClub(props.row.id)">
              {{ props.row.name }}
            </a>
            <a class="button is-text"  @click="editClubValues(props.row)" style="margin-left: 1rem">

              <fa-icon :icon="['fas', 'pencil']" />
            </a>
          </div>
        </b-table-column>
        <b-table-column
          label="RR"
          field="risk_rating"
          :custom-sort="sortByGenerator('risk_rating')"
          sortable>
          {{ props.row.risk_rating }}
        </b-table-column>
        <b-table-column
          label="Exp APV"
          numeric
          field="expected_apv"
          :custom-sort="sortByGenerator('expected_apv')"
          sortable>
          <div style="min-width: 4rem">
            {{ props.row.expected_apv | currency }}
          </div>
        </b-table-column>
        <b-table-column
          label="LTM PV"
          numeric
          field="actual_apv"
          :custom-sort="sortByGenerator('actual_apv')"
          sortable>
          <div style="min-width: 4rem">
            {{ props.row.actual_apv | currency }}
          </div>
        </b-table-column>
        <b-table-column
          :visible="activeTab === 'Payments'"
          numeric
          label="EPC"
          field="expected_prepayment_coefficient"
          :custom-sort="sortByGenerator('expected_prepayment_coefficient')"
          sortable>
          {{ props.row.expected_prepayment_coefficient ? `${props.row.expected_prepayment_coefficient / 100}%` : '-' }}
        </b-table-column>
        <b-table-column
          :visible="activeTab === 'Payments'"
          numeric
          label="APC"
          field="actual_prepayment_coefficient"
          sortable>
          {{ props.row.actual_prepayment_coefficient ?  `${props.row.actual_prepayment_coefficient}%` : '-' }}
        </b-table-column>
        <b-table-column
          :visible="activeTab === 'Payments'"
          numeric
          label="Ann PV%"
          field="annual_processing_volume_percentage"
          :cell-class="getCellClass(props.row.annual_processing_volume_percentage, activeTab, true)"
          :custom-sort="sortByGenerator('annual_processing_volume_percentage')"
          sortable>
          <div style="min-width: 4rem">
            {{ props.row.annual_processing_volume_percentage !== '' ? `${props.row.annual_processing_volume_percentage}%` : '-' }}
          </div>
        </b-table-column>
        <b-table-column
          :visible="activeTab === 'Refunds'"
          numeric
          label="Ann RV%"
          field="annual_refund_volume_percentage"
          :cell-class="getCellClass(props.row.annual_refund_volume_percentage, activeTab, true)"
          sortable>
          <div style="min-width: 4rem">
            {{ props.row.annual_refund_volume_percentage ? `${props.row.annual_refund_volume_percentage}%` : '-' }}
          </div>
        </b-table-column>
        <b-table-column
          :visible="activeTab === 'Chargebacks'"
          numeric
          label="Ann CBV%"
          field="annual_chargeback_volume_percentage"
          :cell-class="getCellClass(props.row.annual_chargeback_volume_percentage, activeTab, true)"
          sortable>
          <div style="min-width: 4.5rem">
            {{ props.row.annual_chargeback_volume_percentage ? `${props.row.annual_chargeback_volume_percentage}%` : '-' }}
          </div>
        </b-table-column>
        <b-table-column
          v-for="(month, index) in props.row.kpisByMonth"
          :key="index"
          :label="month.date.format('MMM YY')"
          numeric
          :field="`${month.date.format('MMM YY')}`"
          :cell-class="getCellClass(props.row.kpisByMonth[index].value, activeTab)"
          :custom-sort="sortByGenerator(`kpisByMonth[${index}].value`)"
          sortable>
          <div style="min-width: 4rem">{{ formatPercentage(props.row.kpisByMonth[index].value) }}</div>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Clubs Found</p>
          </div>
        </section>
      </template>
    </TablePaginated>
    <FinancialFilter
      :filters-active="hasFiltersActive"
      :activeTab="activeTab"
      @risk_rating="setRiskRating=$event"/>
    <SetClubValuesModal ref="setClub" @refresh="fetchFinancialKpis"/>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import FTLegend from './FTLegend';
import PageNavigation from '../layout/navigation/secondary/PageNavigation';
import SpecificClubImportModal from './SpecificClubImportModal';
import SpecificClubExportModal from './SpecificClubExportModal';
import SetClubValuesModal from './SetClubValuesModal';
import SetSystemModal from './SetSystemModal';
import TablePaginated from '../layout/TablePaginated';
import FinancialFilter from './FinancialFilter';

export default {
  components: {
    PageNavigation,
    SpecificClubImportModal,
    SpecificClubExportModal,
    TablePaginated,
    Spinner,
    SetClubValuesModal,
    SetSystemModal,
    FinancialFilter,
    FTLegend,
  },
  data() {
    return {
      merchantAccountsByClubId: [],
      activeTab: 'Payments',
      loading: false,
      clubs: [],
      clubInternals: [],
      values: {
        annual_payment_concern_percentage: 125,
        annual_payment_risk_percentage: 150,
        monthly_payment_concern_percentage: 50,
        monthly_payment_risk_percentage: 75,

        annual_refund_concern_percentage: 5,
        annual_refund_risk_percentage: 10,
        monthly_refund_concern_percentage: 1,
        monthly_refund_risk_percentage: 3,

        annual_chargeback_concern_percentage: 0.5,
        annual_chargeback_risk_percentage: 1,
        monthly_chargeback_concern_percentage: 0.2,
        monthly_chargeback_risk_percentage: 0.5,
      },
      searchQuery: '',
    };
  },
  computed: {
    clubInternalsByID() {
      return this.clubInternals.reduce((acc, club) => ({ ...acc, [club.id]: club }), {});
    },
    tableData() {
      return this.clubs.filter(club => this.clubInternalsByID[club.id]).map((club => ({ ...club,
        risk_rating: this.clubInternalsByID[club.id]?.risk_rating || 'N/A',
        expected_apv: this.clubInternalsByID[club.id]?.expected_apv || '-',
        actual_apv: this.getAnnualProcessingVolume(club.groupedKpis[10016]) || '-',
        expected_prepayment_coefficient: this.clubInternalsByID[club.id]?.expected_prepayment_coefficient || 0,
        actual_prepayment_coefficient: this.getActualPrepaymentCoefficient(club.groupedKpis[10016]),
        annual_processing_volume_percentage: this.getVolumePercentage(club.groupedKpis[10016], club.id),
        annual_refund_volume_percentage: this.getVolumePercentage(club.groupedKpis[10018], club.id),
        annual_chargeback_volume_percentage: this.getVolumePercentage(club.groupedKpis[10020], club.id),
        primary_vendor: this.bestFitMerchant(this.merchantAccountsByClubId[club.id] || [], false)?.vendor ?? '-',
        kpisByMonth: this.getKpisByMonth(club.groupedKpis[this.kpisByTab], club.id),
      })));
    },
    filteredClubs() {
      return this.tableData.filter(club => this.matchesFinancialFilter(club) &&
      this.matchesName(club, this.searchQuery));
    },
    tabs() {
      return [
        { name: 'Payments', ctas: this.ctas },
        { name: 'Refunds', ctas: this.ctas },
        { name: 'Chargebacks', ctas: this.ctas },
      ];
    },
    ctas() {
      const ret = [];
      ret.push(this.EXPORT_CLUBS);
      ret.push(this.IMPORT_CLUBS);
      ret.push(this.ADD_SYSTEM_VALUES);
      return ret;
    },
    dropdownCtas() {
      const ret = [];
      ret.push(this.EXPORT_CLUBS);
      return ret;
    },
    EXPORT_CLUBS() {
      return { text: 'Export Club Values', icon: 'sign-in', icon_transform: 'rotate-90 grow-3', dropdown_item: true };
    },
    IMPORT_CLUBS() {
      return { text: 'Import Club Values', icon: 'file-import', icon_transform: 'grow-3', dropdown_item: true };
    },
    ADD_SYSTEM_VALUES() {
      return 'Set System Values';
    },
    kpisByTab() {
      switch (this.activeTab) {
        case 'Refunds':
          return 10018;
        case 'Chargebacks':
          return 10020;
        default:
          return 10016;
      }
    },
    hasFiltersActive() {
      return !_.isEmpty(this.activeFilters);
    },
    activeFilters() {
      return this.$store.getters.activeFiltersForContext('financialTracking');
    },
  },
  created() {
    this.loading = true;
    Promise.all([
      this.fetchMerchantAccounts(),
      this.fetchFinancialKpis(),
      this.fetchInternalClubs(),
    ]).then(() => {
      this.loading = false;
    });
  },
  methods: {
    bestFitMerchant(merchants) {
      if (!merchants) return null;
      return merchants.find(merchant => !merchant.flags.includes('Secondary'));
    },
    sortByGenerator(key) {
      let emptyVal;
      let compFunc = (a, b) => a - b;
      switch (key) {
        case 'annual_processing_volume_percentage':
          emptyVal = '';
          break;
        case 'expected_prepayment_coefficient':
          emptyVal = 0;
          break;
        case 'risk_rating':
          emptyVal = 'N/A';
          compFunc = (a, b) => a.localeCompare(b);
          break;
        case 'primary_vendor':
          emptyVal = '-';
          compFunc = (a, b) => a.localeCompare(b);
          break;
        default:
          emptyVal = '-';
          break;
      }
      return (a, b, isAsc) => {
        const A = _.get(a, key);
        const B = _.get(b, key);
        if (A === emptyVal) return 1;
        if (B === emptyVal) return -1;
        return isAsc ? compFunc(A, B) : compFunc(B, A);
      };
    },
    cta(event) {
      switch (event.text ?? event) {
        case 'Import Club Values':
          this.$refs.import_modal.showModal();
          break;
        case 'Export Club Values':
          this.$refs.export_modal.showModal();
          break;
        case 'Set System Values':
          this.$refs.system_modal.showModal();
          break;
        default:
      }
    },
    quit() {
      this.$refs.modal.hide();
    },
    newSystemValues(values) {
      this.values = values;
    },
    editClubValues(club) {
      this.$refs.setClub.showModal(club);
    },
    matchesName(tableData, searchQuery) {
      return tableData?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase() ?? '');
    },
    matchesFinancialFilter(club) {
      if (!this.hasFiltersActive) return true;
      return Object.keys(this.activeFilters).every((key) => {
        if (key === 'advanced' || key === 'advancedR') {
          // TODO: error  Invalid loop. Its body allows only one iteration  no-unreachable-loop
          for (let i = 0; i < this.activeFilters[key].allow.length; i += 1) { // eslint-disable-line no-unreachable-loop
            switch (this.activeFilters[key].allow[i]) {
              case 'expected_apv':
                return club.expected_apv === '-';
              case 'risk_rating':
                return club.risk_rating === 'N/A';
              case 'expected_prepayment_coefficient':
                return club.expected_prepayment_coefficient === 0;
              default:
                return false;
            }
          }
          return false;
        } else {
          return this.activeFilters[key].matches(club);
        }
      });
    },
    getCellClass(percentage, tab, isAnnual) {
      if (tab === 'Payments') {
        if (isAnnual) {
          if (percentage >= this.values.annual_payment_risk_percentage) return 'is-danger';
          else if (percentage >= this.values.annual_payment_concern_percentage) return 'is-warning';
        } else if (!isAnnual && percentage >= this.values.monthly_payment_risk_percentage) {
          return 'is-danger';
        } else if (!isAnnual && percentage >= this.values.monthly_payment_concern_percentage) {
          return 'is-warning';
        }
      } else if (tab === 'Refunds') {
        if (isAnnual) {
          if (percentage >= this.values.annual_refund_risk_percentage) return 'is-danger';
          else if (percentage >= this.values.annual_refund_concern_percentage) return 'is-warning';
        } else if (!isAnnual && percentage >= this.values.monthly_refund_risk_percentage) {
          return 'is-danger';
        } else if (!isAnnual && percentage >= this.values.monthly_refund_concern_percentage) {
          return 'is-warning';
        }
      } else if (tab === 'Chargebacks') {
        if (isAnnual) {
          if (percentage >= this.values.annual_chargeback_risk_percentage) return 'is-danger';
          else if (percentage >= this.values.annual_chargeback_concern_percentage) return 'is-warning';
        } else if (!isAnnual && percentage >= this.values.monthly_chargeback_risk_percentage) {
          return 'is-danger';
        } else if (!isAnnual && percentage >= this.values.monthly_chargeback_concern_percentage) {
          return 'is-warning';
        }
      }
      return '';
    },
    formatPercentage(num) {
      if (num === '-') return num;
      return `${num}%`;
    },
    calculateMean(arr) {
      const mean = arr.reduce((acc, curr) => acc + curr, 0) / 12;
      return mean;
    },
    calculateStdDev(arr, mean) {
      let num = 0;
      for (let i = 0; i < arr.length; i += 1) {
        num += (arr[i] - mean) ** 2;
      }
      return Math.sqrt(num / 11);
    },
    getLastYearKpis(kpis) {
      let lastYearKpis = [];
      const minDate = moment().subtract(13, 'months').endOf('month');
      const maxDate = moment().subtract(1, 'months').endOf('month');
      if (!kpis) {
        for (let i = 0; i < 12; i += 1) lastYearKpis.push(0);
        return lastYearKpis;
      } else {
        lastYearKpis = kpis.filter(kpi => minDate.isSameOrBefore(moment(kpi.captured_on))
          && maxDate.isSameOrAfter(moment(kpi.captured_on))).map(kpi => kpi.value);
        while (lastYearKpis.length < 12) lastYearKpis.push(0);
      }
      return lastYearKpis;
    },
    getActualPrepaymentCoefficient(kpis) {
      const lastYearKpis = this.getLastYearKpis(kpis);
      const mean = this.calculateMean(lastYearKpis);
      if (!mean || !kpis) return '';
      return Math.round(10000 * this.calculateStdDev(lastYearKpis, mean) / mean / Math.sqrt(12)) / 100;
    },
    getAnnualProcessingVolume(kpis) {
      return this.getLastYearKpis(kpis).reduce((acc, curr) => acc + curr, 0);
    },
    getVolumePercentage(kpis, id) {
      const expectedApv = this.clubInternalsByID[id].expected_apv;
      const actualApv = this.getAnnualProcessingVolume(kpis);
      if (!expectedApv && actualApv) return 'inf';
      if (!expectedApv || !kpis) return '';
      return Math.round(10000 * (actualApv / expectedApv)) / 100;
    },
    getKpisByMonth(kpis, id) {
      const arr = [];
      const expectedApv = this.clubInternalsByID[id].expected_apv;
      const kpiDate = moment().subtract(1, 'days');
      if (!expectedApv || !kpis) {
        for (let i = 0; i < 13; i += 1) {
          arr.push({ date: moment(kpiDate), value: '-' });
          kpiDate.month(kpiDate.month() - 1).endOf('month');
        } return arr;
      }
      for (let i = 0; i < 13; i += 1) {
        let kpiValue = kpis.find(kpi => kpiDate.isSame(moment(kpi.captured_on), 'month'))?.value;
        if (!kpiValue) kpiValue = 0;
        arr.push({ date: moment(kpiDate), value: Math.round(10000 * (kpiValue / expectedApv)) / 100 });
        kpiDate.month(kpiDate.month() - 1);
      } return arr;
    },
    viewClub(id) {
      this.$router.push({ name: 'ClubFinancialTracking', params: { club: id, systemValues: this.values } });
    },
    fetchInternalClubs() {
      return api().get('/cst/club_internals/').then((res) => {
        this.clubInternals = res.data;
      });
    },
    fetchMerchantAccounts() {
      return api().post('/cst/merchant_accounts/search?populate=user').then((res) => {
        this.merchantAccountsByClubId = _.groupBy(res.data, 'club_id');
      });
    },
    fetchFinancialKpis() {
      return api().post('/cst/clubs/search?populate=kpis,kpis:merchant_payment_count,kpis:merchant_payment_amount,kpis:merchant_refund_count,kpis:merchant_refund_amount,kpis:merchant_dispute_count,kpis:merchant_dispute_amount')
        .then((ret) => {
          this.clubs = ret.data.filter(club => !club.is_test_club && club.config?.enable_program_administration)
            .map(club => ({ ...club, groupedKpis: _.groupBy(club.kpis, 'kpi_id') }));
        });
    },
  },
};
</script>
<style lang="sass" scoped>
@import "~bulma/sass/utilities/mixins"
.club-name
  text-decoration: underline
.exit-button
  padding-left: 22rem
.wrap-class
  display: flex
  justify-content: left
  position: relative
.legend-title
  font-size: 1.3rem
#financial-tracking-table
  .table th
    vertical-align: bottom
  .sticky-column
    display: flex
    justify-content: space-between
  .is-warning
    background-color: rgba($warning-dark, 0.25)
  .is-danger
    background-color: rgba($danger, 0.25)
    color: $grey-darker
</style>

<template>
  <b-tag v-if="status !== 'Approved' && status !== 'Canceled'"
    class="status_tag text_tag"
    :class="tagClasses"
    rounded>{{status | capitalize}}</b-tag>
  <span class="text_tag" v-else-if="status === 'Approved'">
    <fa-icon class="has-text-success" :icon="['fas','check']" />
    Approved
  </span>
  <span class="text_tag" v-else-if="status === 'Canceled'">
    <fa-icon class="has-text-danger" :icon="['far', 'times']" />
    Canceled
  </span>
</template>

<script>
export default {
  props: {
    status: { type: String, required: true },
  },
  data() {
    return {
      statusText: 'Test',
    };
  },
  computed: {
    tagClasses() {
      if (this.status === 'Not Submitted') {
        return 'is-danger';
      }
      if (this.status === 'In Review') {
        return 'is-warning';
      }
      if (this.status.includes('ID:')) {
        return 'has-background-light-grey';
      }
      if (this.status === 'In Progress') {
        return 'has-background-dark-grey';
      }
      if (this.status === 'today' || this.status === 'current week') {
        return 'is-info-dark has-text-white';
      }
      return '';
    },
  },
};
</script>

<style scoped lang="sass">
.status_tag
  font-family: $family-montserrat
  color: $white
.text_tag
  font-weight: 600
  font-family: $family-montserrat
  font-size: .75rem
  white-space: nowrap
.has-background-dark-grey
  background-color: $grey
.has-background-light-grey
  background-color: $grey-lighter
</style>

<template>
  <div>
    <div>
      <PageNavigation
        page-title="Underwriting"
        :ctas="ctas"
        @ctaClicked="cta($event)"/>
    </div>
    <div v-if="loading">
      <Spinner/>
    </div>
    <div v-else>
      <TablePaginated
        name="underwritingTable"
        :data="sortedTable"
        :filters-active="hasFiltersActive"
        searchable
        @queryUpdated="searchQuery= $event">
        <template slot="header-left">
            <div v-if="!noOpenData" style="display: flex;flex-direction: column">
              <div style="display: flex;align-items: flex-end">
                <b-tabs
                  type="is-toggle"
                  v-model="activeTab"
                  class="b-tabs-no-content">
                  <b-tab-item label = "Open"/>
                  <b-tab-item label = "All"/>
                </b-tabs>
              </div>
            </div>
          </template>
          <template slot-scope="props">
            <b-table-column
              header-class="is-unselectable"
              width="2rem"
              field="alert">
              <b-tooltip
                v-if="dateWarning(props.row.due_date, props.row.target_date) &&
                  !(props.row.kyc_status === 'Approved' && props.row.underwriting_status === 'Approved')"
                label="Approval Date is After Target Date"
                type="is-dark"
                position="is-bottom"
                class="error-tooltip"
                multilined>
                <fa-icon class="icon_orange"
                  :icon="['fas', 'exclamation-triangle']"
                />
              </b-tooltip>
              <b-tooltip
                header-class="is-unselectable"
                v-else-if="alertToggle(props.row.due_date, props.row.target_date) &&
                  !(props.row.kyc_status === 'Approved' && props.row.underwriting_status === 'Approved')"
                label="Approval date is within 2 weeks of target date"
                type="is-dark"
                position="is-bottom"
                class="error-tooltip"
                multilined>
                <fa-icon class="icon_orange"
                  :icon="['fas', 'exclamation-triangle']"
                />
              </b-tooltip>
            </b-table-column>
            <b-table-column
              header-class="is-unselectable"
              label="Club Name"
              field="name"
              sortable>
              {{ props.row.name }}
            </b-table-column>
            <b-table-column
              label="Onboarding Type"
              field="onboarding_type"
              sortable
              header-class="is-unselectable">
              {{ props.row.onboarding_type | capitalize }}
            </b-table-column>
            <b-table-column
              header-class="is-unselectable"
              label="Due Date"
              field="due_date"
              sortable>
              <span :class="dateApproachWarning(props.row.due_date) &&
                !(props.row.kyc_status === 'Approved' && props.row.underwriting_status === 'Approved') ? 'date-warning' : ''">
                {{ props.row.due_date | formatDate('MMM D, YYYY') }}
              </span>
            </b-table-column>
            <b-table-column
              label="Target Date"
              field="target_date"
              header-class="is-unselectable"
              sortable>
              {{ props.row.target_date | formatDate('MMM D, YYYY') }}
            </b-table-column>
            <b-table-column
              label="Sales Rep"
              field="sales_user_id"
              header-class="is-unselectable"
              sortable>
              {{ userName(props.row.sales_user_id) }}
            </b-table-column>
            <b-table-column
              label="Lead CS Staff Member"
              field="lead_cs_user_id"
              header-class="is-unselectable"
              sortable>
              {{ userName(props.row.lead_cs_user_id) ? userName(props.row.lead_cs_user_id) : '-' }}
            </b-table-column>
            <b-table-column
              label="Underwriting Status"
              field="underwriting_status"
              header-class="is-unselectable"
              sortable>
              <div class="underwriting-table__badge-section">
                <StatusTag class="underwriting-table__status-section__badge" :status="props.row.underwriting_status" />
              </div>
            </b-table-column>
            <b-table-column
              label="KYC Status"
              field="kyc_status"
              header-class="is-unselectable"
              sortable>
              <StatusTag class="underwriting-table__status-section__Badge" :status="props.row.kyc_status" />
            </b-table-column>
            <b-table-column
              v-if="canAccess(['success', 'product', 'operations'])"
              centered
              header-class="is-unselectable"
              label="Actions"
              field="actions">
              <div class="underwriting-table__actions">
                <a
                  class="button is-text underwriting-table__actions__link--edit"
                  @click="editPlan(props.row)"
                >
                <fa-icon :icon="['fas', 'pencil']" />
                </a>
                <b-dropdown
                  ref="dropdown"
                  aria-role="list"
                  position="is-bottom-left">
                  <a
                    class="button is-text underwriting-table__actions__link"
                    slot="trigger">
                    <fa-icon :icon="['far', 'ellipsis-h']" />
                  </a>
                  <b-dropdown-item v-if="!archiveView"
                    aria-role="listitem">
                    <div class="columns is-mobile">
                      <div @click="archive(props.row)" class="column has-text-danger">
                        <fa-icon
                          :icon="['far', 'archive']"
                          class="is-danger"
                          style="margin-right: 0.25rem"
                          />
                        Archive Plan
                      </div>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item v-else-if="archiveView"
                    aria-role="listitem">
                    <div class="columns is-mobile">
                      <div @click="unarchive(props.row)" class="column">
                        <fa-icon
                          :icon="['fas', 'redo']"
                          class="is-danger"
                          style="margin-right: 0.25rem"
                          />
                        Restore Plan
                      </div>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>No Clubs Found</p>
              </div>
            </section>
          </template>
        </TablePaginated>
      </div>
      <UnderwritingFilter/>
      <AddUnderWritingPlanModal @noteCreated="refresh"
        @noteDeleted="refresh"
        :clubPull="sortedTable"
        :totalData="plansData"
        ref="addEditPlan"
        @dismissed="dismissedModal()"/>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import TablePaginated from '@/components/layout/TablePaginated';
import Spinner from '@/components/common/Spinner';
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';
import AddUnderWritingPlanModal from '@/components/underwriting/AddUnderWritingPlanModal';
import StatusTag from '@/components/underwriting/StatusTag';
import UnderwritingFilter from '@/components/underwriting/UnderwritingFilter';
import api from '@/http-playmetrics';

// remove after DB fix
const normalizeOnboardingType = (obt) => {
  if (obt === 'Full Service') return 'full';
  if (obt === 'Mid-Service') return 'mid';
  if (obt === 'Self Service') return 'self';
  return obt;
};

export default {
  components: { TablePaginated, Spinner, PageNavigation, AddUnderWritingPlanModal, StatusTag, UnderwritingFilter },
  created() {
    this.fetchData();
  },
  data() {
    return {
      loading: false,
      merchAccounts: [],
      onboardingPlans: [],
      plansData: [],
      activeTab: 0,
      searchQuery: '',
    };
  },
  computed: {
    noOpenData() {
      return _.isEmpty(this.filterRowData.filter(plan => this.checkOpen(plan)).filter(plan => !plan.archived_at));
    },
    sortedTable() {
      const copyTable = [...this.filterRowData];
      if (this.activeTab === 1 || this.noOpenData) {
        return copyTable.sort((a, b) => moment(a.due_date).diff(moment(b.due_date)));
      } else {
        return copyTable.filter(club => this.checkOpen(club)).sort((a, b) => moment(a.due_date).diff(moment(b.due_date)));
      }
    },
    filterRowData() {
      if (this.$store.getters.activeFiltersFor('underwritingPlan').advancedToggle) {
        return this.tableRows.filter(plan => plan.archived_at);
      } else {
        return this.tableRows.filter(plan => !plan.archived_at);
      }
    },
    archiveView() {
      return this.$store.getters.activeFiltersFor('underwritingPlan').advancedToggle;
    },
    hasFiltersActive() {
      return !_.isEmpty(this.$store.getters.activeFiltersFor('underwritingPlan'));
    },
    tableRows() {
      return this.plansData.map(plan => ({
        name: this.clubName(plan.club_id),
        club_id: plan.club_id,
        id: plan.id,
        due_date: plan.due_date,
        target_date: plan.live_target_date,
        vendor: plan.vendor,
        created_at: plan.created_at,
        archived_at: plan.archived_at,
        underwriting_status: plan.status,
        kyc_status: this.getKYCStatus(this.merchAccounts.find(c => c.club_id === plan.club_id)),
        onboarding_type: normalizeOnboardingType(this.onboardingType(plan.club_id)),
        created_by_user_id: plan.created_by_user_id,
        sales_user_id: this.storedClubs.find(club => club.id === plan.club_id).config?.sales_contact_user_id,
        lead_cs_user_id: this.onboardingPlans.find(oPlan => oPlan.club_id === plan.club_id)?.cs_lead_user_id || '',
        secondary_cs_user_id: this.onboardingPlans.find(oPlan => oPlan.club_id === plan.club_id)?.cs_secondary_user_id || '',
        notes: plan.notes,
      })).filter(plan => this.matchesUnderwritingPlanFilter(plan) && this.searchMatch(plan));
    },
    storedUsers() {
      return this.$store.getters.users;
    },
    storedClubs() {
      return this.$store.getters.clubs;
    },
    ADD_PLAN() {
      return 'Add Plan';
    },
    ctas() {
      const ret = [];
      if (this.canAccess(['success', 'product', 'operations'])) {
        ret.push(this.ADD_PLAN);
      }
      return ret;
    },
  },
  methods: {
    fetchMerchantAccounts() {
      return api().post('cst/merchant_accounts/search').then((res) => {
        if (res.status === 200) {
          this.merchAccounts = res.data;
        }
      });
    },
    getKYCStatus(plan) {
      if (plan) {
        if (plan.flags.includes('Enabled')) {
          return 'Approved';
        } else if ((plan.flags.length === 1 && plan.flags.includes('')) && !plan.vendor_account_id) {
          return 'Not Submitted';
        } else {
          return 'In Progress';
        }
      }
      return '';
    },
    fetchOnboardingPlans() {
      return api().post('cst/club_onboarding_plans/search').then((res) => {
        if (res.status === 200) {
          this.onboardingPlans = res.data;
        }
      });
    },
    onboardingType(id) {
      return this.storedClubs.find(club => club.id === id).config?.onboarding_type;
    },
    refresh() {
      this.fetchPlans();
    },
    unarchive(club) {
      this.$confirm.showModal({
        confirmText: 'Yes, Restore Plan',
        message: `You are about to restore the underwriting plan for <strong>${club.name}</strong>.
                  <br><br>
                  Are you sure you want to continue?`,
        title: 'Restore Underwriting Plan',
        type: 'is-primary',
        icon: 'redo',
        onConfirm: () => {
          api().post(`/cst/underwritings/${club.id}/unarchive`).then(() => {
            this.$store.commit('setNotification', { toast: true, success: true, message: 'Plan unarchived' });
            this.dismissedModal();
          });
        },
      });
    },
    dismissedModal() {
      this.fetchPlans();
    },
    dateApproachWarning(date) {
      if (moment(date).isBefore(moment(), 'day')) {
        return true;
      }
      return false;
    },
    userName(id) {
      if (!id) {
        return '';
      }
      const userData = this.storedUsers.find(user => user.id === id);
      return `${userData?.first_name || ''} ${userData?.last_name || ''}`;
    },
    clubName(id) {
      return this.storedClubs.find(club => club.id === id)?.name || '';
    },
    fetchData() {
      this.loading = true;
      Promise.all([
        this.fetchPlans(),
        this.fetchOnboardingPlans(),
        this.fetchMerchantAccounts(),
      ]).then(() => {
        this.loading = false;
      });
    },
    fetchPlans() {
      return api().post('/cst/underwritings/search?populate=notes').then((res) => {
        if (res.status === 200) {
          this.plansData = res.data;
        }
      });
    },
    matchesUnderwritingPlanFilter(plan) {
      if (!this.hasFiltersActive) return true;
      const filters = this.$store.getters.activeFiltersFor('underwritingPlan');
      return Object.keys(filters).filter(key => typeof filters[key] !== 'boolean').every(key => filters[key].includes(plan[key]) ||
      (key === 'dueDate' && ((moment(plan.due_date).diff(moment(), 'days') >= 0 && ((filters[key].includes('next 14 days') && moment(plan.due_date).diff(moment(), 'days') <= 14) ||
        (filters[key].includes('next 30 days') && moment(plan.due_date).diff(moment(), 'days') <= 30) || (filters[key].includes('next 3 months') && moment(plan.due_date).diff(moment(), 'months') <= 3))) || (filters[key].includes('past') && moment(plan.due_date).isBefore(moment(), 'days')))) ||
        (key === 'targetDate' && ((moment(plan.target_date).diff(moment(), 'days') >= 0 && ((filters[key].includes('next 14 days') && moment(plan.target_date).diff(moment(), 'days') <= 14) ||
        (filters[key].includes('next 30 days') && moment(plan.target_date).diff(moment(), 'days') <= 30) || (filters[key].includes('next 3 months') && moment(plan.target_date).diff(moment(), 'months') <= 3))) || (filters[key].includes('past') && moment(plan.target_date).isBefore(moment(), 'days')))));
    },
    archive(club) {
      this.$confirm.showModal({
        confirmText: 'Yes, Archive Plan',
        message: `You are about to archive <strong>${this.clubName(club.club_id)}'s</strong> underwriting plan.
                  <br><br>
                  Are you sure you want to continue?`,
        title: 'Archive Underwriting Plan',
        type: 'is-danger',
        icon: 'minus-hexagon',
        onConfirm: () => {
          api().post(`/cst/underwritings/${club.id}/archive`).then(() => {
            this.$store.commit('setNotification', { toast: true, success: true, message: 'Plan archived' });
            this.dismissedModal();
          });
        },
      });
    },
    searchMatch(club) {
      return ((this.clubName(club.club_id) && this.clubName(club.club_id).toLowerCase().includes(this.searchQuery.toLowerCase())));
    },
    editPlan(plan) {
      this.$refs.addEditPlan.show(plan);
    },
    checkOpen(checkStatus) {
      const currDay = moment();
      const checkDueDate = moment(checkStatus.due_date);
      if (((checkStatus.underwriting_status !== 'Approved' || checkStatus.kyc_status !== 'Approved') && checkDueDate.isSameOrAfter(currDay, 'day'))
        || ((checkStatus.underwriting_status !== 'Approved' || checkStatus.kyc_status !== 'Approved') && currDay.isSameOrAfter(checkDueDate, 'day'))) {
        return true;
      }
      return false;
    },
    alertToggle(due, target) {
      const a = moment(due);
      const b = moment(target);
      if (Math.abs(a.diff(b, 'days')) <= 13) {
        return true;
      } else {
        return false;
      }
    },
    dateWarning(due, target) {
      if (moment(due).isAfter(moment(target))) {
        return true;
      }
      return false;
    },
    cta($event) {
      if ($event === this.ADD_PLAN) {
        this.$refs.addEditPlan.show();
      }
    },
    canAccess(depts) {
      return this.$store.getters.canAccess(depts);
    },
  },
};
</script>

<style scoped lang="sass">
.date-warning
  color: $red
.selection-grey
  color: $grey-darker
.icon_orange
  color: $orange
.underwriting-table
  &__badge
    margin-bottom: 0.25rem
  &__actions
    display: flex
    justify-content: center
    align-items: center
    &__link
      color: inherit
      padding: 0rem
      &--edit
        color: inherit
        margin-right: 1rem
        padding: 0rem
</style>
